.side-nav {
  position: fixed;
  width: 240px;
  left: 0;
  top: 0;
  margin: 0;
  transform: translateX(-100%);
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%); //Temporary Firefox Fix
  padding-bottom: 60px;
  background-color: $sidenav-bg-color;
  z-index: 999;
  backface-visibility: hidden;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateX(-105%);

  @extend .z-depth-1;

  // Right Align
  &.right-aligned {
    right: 0;
    transform: translateX(105%);
    left: auto;
    transform: translateX(100%);
  }

  .collapsible {
    margin: 0;
  }


  li {
    float: none;
    line-height: $sidenav-item-height;

    &.active { background-color: rgba(80,180,170,.05); }
  }

  a {
    color: $sidenav-font-color;
    display: block;
    font-size: 1rem;
    height: $sidenav-item-height;
    line-height: $sidenav-item-height;
    padding: 0 $sidenav-padding-right;

    &:hover { background-color: rgba(0,0,0,.05);}

    &.btn, &.btn-large, &.btn-flat, &.btn-floating {
      margin: 10px 15px;
    }

    &.btn,
    &.btn-large,
    &.btn-floating { color: $button-raised-color; }
    &.btn-flat { color: $button-flat-color; }

    &.btn:hover,
    &.btn-large:hover { background-color: lighten($button-raised-background, 5%); }
    &.btn-floating:hover { background-color: $button-raised-background; }
  }
}


// Touch interaction
.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}


// Hidden side-nav for all sizes
.side-nav.fixed {
  a {
    display: block;
    padding: 0 $sidenav-padding-right;
    color: $sidenav-font-color;
  }
}


// Fixed side-nav shown
.side-nav.fixed {
  left: 0;
  transform: translateX(0);
  position: fixed;

  // Right Align
  &.right-aligned {
    right: 0;
    left: auto;
  }
}

// Fixed sideNav hide on smaller
@media #{$medium-and-down} {
  .side-nav.fixed {
    transform: translateX(-105%);

    &.right-aligned {
      transform: translateX(105%);
    }
  }
}


.side-nav .collapsible-body li.active,
.side-nav.fixed .collapsible-body li.active {
  background-color: $primary-color;
  a {
    color: $sidenav-bg-color;
  }
}


#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 120vh;
  background-color: rgba(0,0,0,.5);
  z-index: 997;

  will-change: opacity;
}
