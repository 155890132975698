/* 首頁的 p 太寬 */
.collapsible-body p {
  padding: 1rem;
}

.qa {
  // background-color: #d4d4d4;
  color: #f37789;
  // padding: 2px 10px;
  margin: 3px 0px;
  line-height: 32px;
}


/* SOP 頁面 */
#SOPmountPoint .collapsible-body {
  padding: 5px 20px 0px 20px;
}
#SOPmountPoint .collapsible-body > div {
  padding-bottom: 5px;
}
#SOPmountPoint label {
  font-size: 1.1rem;
  color: #222;
}

.question_lists_not_selected {
  cursor: pointer;
  color: #18bc9c;
}
.question_lists_selected {
  cursor: pointer;
  color: #0c1ea0;
}

mark {
  background-color: #fffa9f;
}

/* TODO : 要用 Noto 還是 YaHei */
// @import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  // font-family: 'Noto Sans TC', 'Helvetica', sans-serif;
  font-family: 'Helvetica', 'Microsoft YaHei', 'Microsoft JhengHei', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  text-shadow: #ddd 0.01em 0.01em 0.01em !important;
}
p {
  padding: 0;
  // -webkit-font-smoothing: antialiased;
}




a:hover {
  text-decoration: underline;
}

kbd {
  padding: 2px 4px;
  // font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .25);
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

.iframe_container {
  text-align: center;
  font-size: 0.8rem;
  color: #999;
  margin: 10px 0px;
}

.iframe_container iframe {
  display: block;
  margin: 0px auto;
}

.img_container a {
  color: #aaa;
}

.img_container {
  text-align: center;
  font-size: 0.5rem;
  color: #bbb;
  margin: 10px 0px;
}

.img_container img {
  display: block;
  margin: 0px auto;
}

.materialboxed {
  box-shadow: 1px 1px 5px #aaa;
}

ul.disk {
  padding-left: 30px;
}
ul.disk > li {
  list-style-type:disc;
}

#main_article h3.header {
  text-align: center;
  padding-top: 1rem;
  border-top: dashed 1px #bbb;
}

#main_article h4.subheader {
  margin-top: 2rem;
  // padding-top: 5px;
  // border-top: dashed 1px #bbb;
  // padding-bottom: 2px;
  // padding-left: 15px;
  // border-left: dashed 1px #bbb;
}

#main_article {
  font-size: 1.1rem;
}

#main_article hr {
  margin: 10px 0px 40px 0px;
}

/***************
  Bootstrap Customization 關於原梗的區塊
***************/

#section_neta .row {
  margin-bottom: 10px;
}

#section_neta .row div blockquote {
  padding: 5px 0px 5px 10px;
  margin: 0px 0px;
  font-size: 1rem;
}

#section_neta .row div:nth-child(odd) blockquote {
  border-left: 5px solid #ddd;
  border-top: 1px dashed #a9a9a9;
  // border-right: 1px dashed #a9a9a9;
}
#section_neta .row div:nth-child(even) blockquote {
  border-left: 5px solid #ececec;
  border-top: 1px dashed #a9a9a9;
}

blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #aaa;
}
#section_neta .row div:nth-child(odd) blockquote small:before {
  content: '\2014 \00A0';
}
#section_neta .row div:nth-child(even) blockquote small:before {
  content: "> ";
}


#section_neta2 .row {
  margin-bottom: 10px;
}

#section_neta2 .row div blockquote {
  padding: 5px 0px 5px 10px;
  margin: 0px 0px;
  font-size: 1rem;
}

#section_neta2 .row div:nth-child(odd) blockquote {
  border-left: 5px solid #ddd;
  border-top: 1px dashed #a9a9a9;
  // border-right: 1px dashed #a9a9a9;
}
#section_neta2 .row div:nth-child(even) blockquote {
  border-left: 5px solid #ececec;
  border-top: 1px dashed #a9a9a9;
}

blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #aaa;
}
#section_neta2 .row div:nth-child(odd) blockquote small:before {
  content: '\2014 \00A0';
}
#section_neta2 .row div:nth-child(even) blockquote small:before {
  content: "> ";
}



/***************
  左方選單 side-nav 與 下方 footer
***************/

li a .material-icons {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.side-nav .collapsible-header, .side-nav.fixed .collapsible-header {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 0px solid;
  border-right: 0px solid;
  border-color: rgba(150, 150, 150, 0.3);
}

.side-nav.fixed hr{
  border-color: rgba(77, 182, 172, 0.6);
  margin-bottom: 0px;
}

.side-nav.fixed hr.light{
  border-color: rgba(0, 0 ,0, 0.15);
  border-width: 0.5px;
  border-style: dashed;
  margin: 0px;
}

footer.page-footer {
  padding-top: 0px
}








/***************
  HTML Styles
***************/
body {
  background-color: #fcfcfc;
  color: rgba(20, 20, 20, 0.87);
}

p.box {
  padding: 20px;
}

h1,h2,h3,h4,h5,h6 {
  -webkit-font-smoothing: antialiased;
}

nav {
  // background-color: color("teal", "lighten-2");

  a {
    -webkit-font-smoothing: antialiased;
  }
  ul li a:hover, ul li.active {
    background-color: color("teal", "lighten-1");
  }
}

.header {
  color: color("teal", "lighten-2");
  font-weight: 300;
}

.caption {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 30px;
}

.preview {
  background-color: #FFF;
  border: 1px solid #eee;
  padding: 20px 20px;
}

header, main, footer {
  padding-left: 240px;
}
.parallax-demo header,
.parallax-demo main,
.parallax-demo footer {
  padding-left: 0;
}
footer.example {
  padding-left: 0;
}

@media #{$medium-and-down} {
  header, main, footer {
    padding-left: 0;
  }
}

/********************
  Index Page Styles
********************/

// custom styled sidebar
ul.side-nav.fixed li.logo {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 80px;

  &:hover,
  #logo-container:hover {
    background-color: transparent;
  }
}
ul.side-nav.fixed {
  overflow: hidden;

  li {
    line-height: 44px;

    &.active {
      background-color: rgba(80,180,170,.4);
    }

    a {
      font-size: 1.1rem;
      line-height: 44px;
      height: 44px;
    }
  }

  ul.collapsible-accordion {
    background-color: #FFF;
  }

  // Only show scrollbar on hover
  &:hover {
    overflow-y: auto;
  }
}

.bold > a {
  font-weight: bold;
}

#logo-container {
  height: 57px;
  margin-bottom: 32px;
}

nav.top-nav {
  height: 122px;
  box-shadow: none;

  a.page-title {
    line-height: 122px;
    font-size: 48px;
  }
}
a.button-collapse.top-nav {
  position: absolute;
  text-align: center;
  height: 48px;
  width: 48px;
  left: 7.5%;
  top: 0;
  float: none;
  margin-left: 1.5rem;
  color: #fff;
  font-size: 36px;
  z-index: 2;

  &.full {
    line-height: 122px;
  }

  i {
    font-size: 32px;
  }
}

@media #{$small-and-down} {
  a.button-collapse.top-nav {
    left: 5%;
  }
}

@media #{$medium-and-down} {
  nav .nav-wrapper {
    text-align: center;

    a.page-title {
      font-size: 36px;
    }
  }
}

@media #{$large-and-up} {
  .container {
    width: 85%;
  }
}


#front-page-logo {
  display: inline-block;
  height: 100%;
  pointer-events: none;
}

@media only screen and (max-width: 992px) {
  #front-page-nav ul.side-nav {
    li {
      float: none;
      padding: 0 15px;

      &:hover { background-color: #ddd; }
      .active { background-color: transparent; }
    }
    a {
      color: #444;
    }
  }
}


#responsive-img {
  width: 80%;
  display:block;
  margin: 0 auto;
}

#index-banner {
  background-color: color("teal", "lighten-2");
  .container {
    position: relative;
  }
  .header {
    color: #FFF;
  }
  h4 {
    margin-bottom: 40px;
  }
  h1 {
    margin-top: 16px;
  }
}
@media #{$medium-and-down} {
  #index-banner {
    h1 {
      margin-top: 60px;
    }
    h4 {
      margin-bottom: 15px;
    }
  }
}
@media #{$small-and-down} {
  #index-banner {
    h4 {
      margin-bottom: 0;
    }
  }
}

// Latest commit widget
.github-commit {
  padding: 14px 0;
  height: 64px;
  line-height: 36px;
  background-color: #5c5757;
  color: hsl(0, 0%, 90%);
  font-size: .9rem;
}
@media #{$medium-and-down} {
  .github-commit {
    text-align: center;
  }
}

#github-button {
  background-color: #6f6d6d;
  transition: .25s ease;
  &:hover {
    background-color: #797777;
  }
}

.sha {
  color: hsl(0, 0%, 94%) ;
 margin: 0 6px 0 6px;
}

#download-button {
  background-color: color("teal", "lighten-3");
  width: 260px;
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    background-color: lighten(color("teal", "base"), 30%);
  }
}

.promo{
  width: 100%;

  i {
    margin: 40px 0;
    color: color("teal", "lighten-2");
    font-size: 7rem;
    display: block;
  }
}

.promo-caption {
  font-size: 1.7rem;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;

}

#front-page-nav {
  background-color: #FFF;
  position: relative;
  a {
      color: color("teal", "lighten-2");
    }
  li {
    &:hover {
      background-color: color("teal", "lighten-5");
    }
    &.active {
      background-color: color("teal", "lighten-5");
    }
  }
  .container {
    height: inherit;
  }
}

// Grid doc styles

.col.grid-example {
  border: 1px solid #eee;
  margin: 7px 0;
  text-align: center;
  line-height: 50px;
  font-size: 28px;
  background-color: tomato;
  color: white;
  padding: 0;

  span {
    font-weight: 200;
    line-height: 50px;
  }
}

.promo-example {
  overflow: hidden;
}


/*******************
  Flat Site Mockup
*******************/

#site-layout-example-left {
  background-color: color("blue-grey", "lighten-2");
  height: 300px;
}
#site-layout-example-right {
  background-color: color("teal", "lighten-1");
  height: 300px;
}
#site-layout-example-top {
  background-color: color("red", "lighten-2");
  height: 42px;
}

.flat-text-header {
  height: 35px;
  width: 80%;
  background-color: rgba(255,255,255,.15);
  display: block;
  margin: 27px auto;
}
.flat-text {
  height: 25px;
  width: 80%;
  background-color: rgba(0,0,0,.15);
  display: block;
  margin: 27px auto;
  &.small {
    width: 25%;
    height: 25px;
    background-color: rgba(0,0,0,.15);
  }
  &.full-width {
    width: 100%;
  }
}

/**********************
**********************/

/*****************
  Chrome Browser
*****************/
$bottomColor: #E2E2E1;
$topColor: lighten($bottomColor, 2%);

$border: $bottomColor;

$width: 100%;
$height: auto;

.browser-window {
  text-align: left;
  width: $width;
  height: $height;
  display: inline-block;
  border-radius: 5px 5px 2px 2px;
  background-color: #fff;
  margin: 20px 0px;
  overflow: hidden;

    .top-bar {
    height: 30px;
    border-radius: 5px 5px 0 0;
    border-top: thin solid lighten($topColor, 1%);
    border-bottom: thin solid darken($bottomColor, 1%);
    background: linear-gradient($topColor, $bottomColor);
  }
}

.browser-window .circle {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: lighten($topColor, 10%);
  margin-right: 1px;
}
#close-circle {
  background-color: #FF5C5A;
}
#minimize-circle {
  background-color: #FFBB50;
}
#maximize-circle {
  background-color: #1BC656;
}
.browser-window .circles { margin: 5px 12px; }
.browser-window .content {
  margin: 0;
  width: 100%;
  // min-height: 100%;
  display: inline-block;
  border-radius: 0 0 5px 5px;
  background-color: #fafafa;
}
.browser-window .row {
  margin: 0;
}

.clear { clear: both; }

/**********************
**********************/


// Color Wheel
.dynamic-color {

  .red, .pink, .purple, .deep-purple, .indigo, .blue, .light-blue, .cyan, .teal, .green, .light-green, .lime, .yellow, .amber, .orange, .deep-orange, .brown , .grey,  .blue-grey, .black, .white, .transparent {
    height: 55px;
    width: 100%;
    padding: 0 15px;
    line-height: 55px;
    font-weight: 500;
    font-size: 12px;
    display: block;
    box-sizing: border-box;
  }
  .col {
    margin-bottom: 55px;
  }
}

.center {
  text-align: center;
  vertical-align: middle;
}

// Icons
.material-icons.icon-demo {
  line-height: 50px;
}
.icon-container i {
  font-size: 3em;
  margin-bottom: 10px;
}
.icon-container .icon-preview {
  height: 120px;
  text-align: center;
}
.icon-container span {
  display: block;
}

.icon-holder {
  display: block;
  text-align: center;
  width: 150px;
  height: 115px;

  float: left;
  margin: 0 0px 15px 0px;
  p {
    margin: 0 0;
  }
}

// tabs
.tabs-wrapper {
  position: relative;
  height: 48px;
  @extend .hide-on-small-only;

  .row.pinned {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
  }
}


// Shadow demo styling
.shadow-demo {
  background-color: color("teal", "lighten-1");
  width: 100px;
  height: 100px;
  margin: 20px auto;

  @media only screen and (max-width: $small-screen) {
    width: 150px;
    height: 150px;
  }
}

// parallax demo
.parallax-container {

  .text-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -27px;
  }

}

// Table of contents
ul.table-of-contents {
  margin-top: 0;
  padding-top: 0px;
}

// Prism Styling
code, pre {
  position: relative;
  font-size: 1.1rem;
}

.directory-markup {
  font-size: 1rem;
  line-height: 1.1rem !important;
}
pre[class*="language-"] {
  &:before {
    position: absolute;
    padding: 1px 5px;
    background: hsl(30, 10%, 90%);
    top: 0;
    left: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #555;
    content:attr(class);
    font-size: .9rem;
    border: solid 1px rgba(51, 51, 51, 0.12);
    border-top: none;
    border-left: none;
  }
  padding: 25px 12px 7px 12px;
  border: solid 1px rgba(51, 51, 51, 0.12);
}


.toc-wrapper {
  &.pin-bottom {
    margin-top: 84px;
  }

  position: relative;
  margin-top: 40px;
}

#carbonads {
  max-width: 150px;
  display: inline-block;
  position: relative;
  text-align: left;
  -webkit-font-smoothing: antialiased;

  & > span,
  span.carbon-wrap {
    height: 100px;
    display: block;
  }
  a.carbon-img {
    height: 100px;
    display: inline-block;
    margin-right: 10px;
  }
  a.carbon-text,
  input[type="submit"] {
    position: relative;
    top: 0;
    width: 150px;
    vertical-align: top;
    display: inline-block;
    font-size: 13px;
    color: color("red", "lighten-2");
  }
  a.carbon-poweredby {
    position: relative;
    left: 28px;
    font-size: 11px;
    color: color("red", "lighten-3");
  }
}
.buysellads #carbonads {

  & > span,
  span.carbon-wrap { height: auto; }

  a.carbon-text {
    top: 5px;
    left: 0;
    width: 130px;
    display: block;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    color: #E57373;
  }
  a.carbon-poweredby {
    top: 5px;
  }
}
.buysellads-header #carbonads {
  & > span,
  span.carbon-wrap { height: auto; }
  a.carbon-text { color: #fff; }
  a.carbon-poweredby { color: rgba(255,255,255,.8); }
}


// BuySellAds Styling
.buysellads {
  -webkit-font-smoothing: antialiased;
  position: relative;

  .bsa_it.one {
    width: 130px;
    position: absolute;
    left: 0;
    top: 50px;

    .bsa_it_p {
      left: 0;
      bottom: -15px;
    }
    .bsa_it_ad .bsa_it_t { color: color("red", "lighten-2"); }
    .bsa_it_ad .bsa_it_d { color: color("red", "lighten-3"); }
  }

  .bsa_it_ad a {
    display: block;
    width: 130px;
  }
}

.buysellads-header {
  margin-top: 30px;

  .bsa_it.one .bsa_it_p { bottom: -20px; }
}

.bsa_it.one {
  min-width: 230px;
  max-width: 270px;
  display: inline-block;
  text-align: left;

  .bsa_it_ad {
    border: 0;
    padding: 0;
    background-color: transparent;

    .bsa_it_t { color: #fff; }
    .bsa_it_d { color: color("red", "lighten-4"); }
  }

  .bsa_it_p {
    right: auto;
    left: 40px;
    bottom: -5px;

    a { color: color("red", "lighten-4"); }
  }
}



// Footer styling
footer{
  font-size: .9rem;
}
body.parallax-demo footer {
  margin-top: 0;
}

//About page styling
.image-container {
  width: 100%;
  img {
    max-width: 100%;
  }
}


// Mobile page styling

.mobile-image {
 @media #{$small-and-down} {
  max-width: 100%;
 }
}

// Waves page styling
.waves-color-demo {
  .collection-item {
    height: 57px;
    line-height: 57px;
    code {
     line-height: 57px;
    }
  }
  .btn {
    &:not(.waves-light) {
    background-color: color("shades", "white");
    color: #212121;
    }
  }
}

// Card Page styling
.card-panel span, .card-content p{
  -webkit-font-smoothing: antialiased;
}

#images .card-panel .row {
  margin-bottom: 0;
}

// Pushpin Demo styles
.pushpin-demo {
  position: relative;
  height: 100px;
}
#pushpin-demo-1 {
  display: block;
  height: inherit;
  background-color: #ddd;
}

// Valign Demo
.valign-demo {
  height: 400px;
  background-color: #ddd;
}
.talign-demo {
  height: 100px;
  background-color: #ddd;
}

// Transitions demos
#staggered-test li,
#image-test {
  opacity: 0;

}

// Transifex Styling

#tx-live-lang-container {
  background-color: #fcfcfc;
  z-index: 999;

  #tx-live-lang-picker {
    background-color: #fcfcfc;

    li {
      color: $off-black;
      &:hover{
      color: inherit;
      background-color: color("teal", "lighten-5");
      }
    }

  }

  .txlive-langselector-toggle {
    border-bottom: 2px solid color("teal", "lighten-2");
  }

  .txlive-langselector-current {
    color: $off-black;
  }

  .txlive-langselector-marker {
    border-bottom: 4px solid rgba(0,0,0,.61);
  }


}


// Thanks for Downloading

#download-thanks {
  display: none;
}


// Twitter widget
#twitter-widget-0 {
  width: 190px !important;
}

// Search
#nav-mobile li.search {
  &:hover { background-color: #fff; }

  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  margin-top: 1px;
  padding: 1px 0 0 0;
  z-index: 2;

  .search-wrapper {
    &.focused { margin: 0; }

    margin: 0 12px;
    transition: margin .25s ease;

    input#search {
      &:focus { outline: none; }

      display: block;
      font-size: 16px;
      font-weight: 300;
      width: 100%;
      height: 45px;
      margin: 0;
      padding: 0 45px 0 15px;
      border: 0;
    }

    i.material-icons {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }


  .search-results {
    margin: 0;
    border-top: 1px solid #e9e9e9;
    background-color: #fff;

    a {
      &:hover,
      &.focused {
        background-color: #eee;
        outline: none;
      }

      font-size: 12px;
      white-space: nowrap;
    }
  }
}
