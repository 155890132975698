#facebook17 {
  display: none;
  /*reset css*/
  // *{font-family: Arial, sans-serif}
  a{text-decoration: none}
  body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
  form,fieldset,input,textarea,p,blockquote,th,td {
    padding: 0;
    margin: 0 auto;
  }
  ul{
    list-style: none;
  }
  body{
    background-color: #E4E5E9;
  }
  /*main css*/
  .wrapper{
    width: 95%;
  }
  .box{
    width: 320px;
    border: 2px solid #DADBDC;
    background-color: #fff;
    margin-top: 10px;
    // border-radius: 8px 8px 8px 8px;
    border-bottom: 2px solid #C5C6CC;
  }
  .box .sec-content{
  }
  .box .sec-content .publisher-info{
    height: 60px;
    margin-top: 20px;
  }
  .box .sec-content .publisher-info img{
    width: 60px;
    height: 60px;
    background-color: #E0E0E0;
    display: block;
    float: left;
    border: 1px solid #ddd;
  }
  .box .sec-content .publisher-info .uinfo{
    float: left;
    margin-left: 16px;
  }
  .box .sec-content .publisher-info .uinfo span{
    // display: block;
  }
  .box .sec-content .publisher-info .uinfo div.name{
    font-size: 18px;
    color: #2C4486;
    font-weight: bold;
    // line-height: 45px;
  }
  .box .sec-content .publisher-info .uinfo div.timestampContent{
    font-size: 16px;
    color: #858A97;
  }
  .box .sec-content .content{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DADBDC;
  }
  .box nav {
    background-color: transparent;
    box-shadow: none;
    height: auto;
  }
  .box nav small {
    text-align: right;
  }
  .box .sec-content .content p{
    font-size: 16px;
    //line-height: 34px;
  }
  .box .sec-content nav.opera-list{
    height: 50px;
  }
  .box .sec-content nav.opera-list a{
    display: block;
    float: left;
    font-size: 16px;
    color: #6C6C6C;
    line-height: 50px;
    margin-right: 20px;
  }
  .box .sec-content nav.opera-list a:hover{
    text-decoration: underline;
  }
  .box .sec-content nav.opera-list span.likelog{
    // display: none;
    font-size: 12px;
    color: #616161;
    // line-height: 50px;
    float: right;
  }



  .reply-box{
    // display: none;
    width: 320px;
    background-color: #F4F5F6;
    border-radius: 0 0 ;
    border: 2px solid #DADBDC;
    border-top: 0;
    border-bottom: 2px solid #C5C6CC;
    // margin-bottom: 100px;
  }
  .reply-box .comments{
    border-top: 1px solid #DADBDC;
  }
  .reply-box .comments .main-com{
    width: 100%;
  }
  .reply-box .comments .main-com ul{
    margin-top: 10px;
    // margin-bottom: 30px;
  }
  .reply-box .comments .main-com ul li{
    margin-bottom: 10px;
  }
  .reply-box .comments .main-com ul li .mainsw span.rname{
    float: left;
    font-size: 16px;
    color: #2C4486;
    line-height: 28px;
    font-weight: bold;
    padding-right: 10px;
  }
  .reply-box .comments .main-com ul li .mainsw p{
    font-size: 16px;
    line-height: 28px;
  }
  .reply-box .comments .main-com ul li .list{
    color: #8A919F;
    margin-top: 5px;
  }
  .reply-box .comments .main-com ul li .list a{
    font-size: 16px;
    color: #2C4486;
  }
  .reply-box .comments .main-com ul li .list a:hover{
    text-decoration: underline;
  }
  .reply-box .comments .main-com ul li .list span.cm-time{
    font-size: 16px;
    color: #8A919F;
  }
}
